import React from 'react';
import { connect } from 'react-redux';
import Img from "gatsby-image"
import Layout from '../components/layout';
import SEO from '../components/seo';
import DepthLayer from '../components/parallax/DepthLayer';
import { updateCursor, updateWindow } from '../state/state-movement';
import { graphql } from 'gatsby';
import Events from '../svg/events-icon.svg'
import Logo from '../svg/white-logo.svg'
import Arrow from '../svg/left-arrow.svg'
import renderHTML from 'react-render-html';
import moment from 'moment-es6';
import AniLink from "gatsby-plugin-transition-link/AniLink";

class EventsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentActive: false,
    };

    this.previousEvents = [];
    this.currentEvents = [];
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({contentActive: true});
    }, 100);

    this.makeEvents();
  }

  makeEvents = () => {
    console.log(this.props.data.events);
    

    this.props.data.events.events.forEach((event, index) => {
      let thisEvent = (
        <div className={`event-container ${moment(event.dateAndTime).isBefore(moment()) ? 'previous' : ''}`} key={`event-${index}`}>
          <div className="event-container__image">
            <Img fluid={event.eventImage.fluid} alt={event.eventImage.alt}/>
          </div>
          <div className="event-container__content">
            <h3 className="event-container__title">
              {event.title}
              <span className="event-container__title-time">{moment(event.dateAndTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </h3>
            <p className="event-container__description">
              {event.description}
            </p>
            <a href={event.eventUrl} className="event-container__link" target="_blank">Go to event</a>
          </div>
        </div>
      )

      if (moment(event.dateAndTime).isBefore(moment())) {
        this.previousEvents.push(thisEvent)
      } else {
        this.currentEvents.push(thisEvent)
      }

    });

  }

  render () {
    let data = this.props.data.events;
    let depth = this.props.depth;
    let movement = this.props.movement;

    return (
      <>
        <SEO title="Events" bodyType="" description={data.seoDescription}/>
        <Layout 
          transitionStatus={this.props.transitionStatus}
          transitionDirection={this.props.current.state.direction}
          class={`events`}
        >
          <div className={`content ${this.state.contentActive && 'content-active'}`}>
            <div className="content-header">
              <div className="content-header__icon">
                <Events />
              </div>
              <h1 className="content-header__title">Events</h1>
            </div>
            <div className="content-body">
              <div className="content-body__full-width">
                {renderHTML(data.eventPageDescription)}
              </div>
              {this.currentEvents.length > 0 &&
                <>
                  <h2 className="content-body__subtitle">Upcoming</h2>
                  {this.currentEvents}
                </>

              }
              {this.previousEvents.length > 0 &&
                <>
                  <h2 className="content-body__subtitle previous">Previous</h2>
                  {this.previousEvents}
                </>
              }
              <div className="content-body__bottom-link events">
                <AniLink to={`/gallery`} fade duration={1}>
                  <span className="content-link__text">Wanna see some pretty pictures?</span>
                  <Arrow/>
                </AniLink>
              </div>
            </div>
            <Logo className="content-logo"/>
          </div>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    events: datoCmsEvent {
      eventPageDescription
      seoDescription
      events {
        dateAndTime
        description
        title
        eventUrl
        eventImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
          url 
          alt
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    movement: state.movementState,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(EventsPage);